<template>

  <div>

    <div class="top_style">
      <img alt="Vue logo" src="../../../public/logo.png" style="width: 42px; height: 42px;margin-left: 15px">
      <span class="span_center">Lucky Maker</span>

      <div style="width: 100%;">
        <div style="float: right;margin-right: 15px;">
      
          <span class="tab_type" @click="jumpHome">Home</span>
          <span class="tab_type" @click="jumpAboutUs" style="margin-left: 30px">About Us</span>
          <span class="tab_type" @click="jumpContactUs" style="margin-left: 30px">Contact Us</span>

        </div>
      </div>

    </div>

    <div style="background-color: white; padding: 30px;color: white;">
      <div style="font-size: 60px;color: #444444;font-weight: bold">Terms of Use</div>
      <el-divider></el-divider>

      <div style="font-size: 20px; color: #444444">
        <div>NO PURCHASE OR PAYMENT OF ANY KIND IS NECESSARY TO ENTER OR WIN ANY
          GAME.
        </div>
        <div style="margin-top: 30px">SOME GAMES REQUIRE TOKENS TO ENTER. TOKENS ARE PROVIDED FREE OF CHARGE.
        </div>

        <div style="margin-top: 30px">THE APP, THE GAMES, AND ANY SWEEPSTAKES CONTAINED HEREIN ARE NOT SPONSORED BY,
          ENDORSED OR AFFILIATED IN ANY WAY WITH APPLE, INC or GOOGLE INC.
        </div>

        <div style="margin-top: 30px">Welcome to the Lucky Maker App. Lucky Maker</div>
        <div style="margin-top: 0px">provide online and mobile gaming services to authorized users
          (“Company Services”) subject to the following terms and conditions contained herein, including all Official
          Rules
          of the Games played by you (the “Terms”).
        </div>

        <div style="font-size: 24px;color: #444444;font-weight: bold;margin-top: 30px">By using the Lucky Maker App, you
          agree to these
          Terms. Please read them carefully.
        </div>

        <div style="margin-top: 30px"> By accessing or using the App, or by creating an Account, you
          acknowledge your agreement to be bound by these Terms. If you do not agree to be bound by these Terms, please
          do
          not use this App or play any Games contained herein. Lucky Maker. reserves the right to update or modify
          these Terms at any time, and without prior notice to you. Your use of the App following such modification or
          update constitutes your acceptance to be bound by these Terms as changed or modified. Lucky Maker.
          encourages you to read through and review these Terms each time you access the App and utilize the Company
          Services.
        </div>
        <div style="margin-top: 30px">
          <span style="font-weight: bold">1. Privacy:</span>
          <span> Please review our Privacy Notice, which also governs your use of the App and Company Services, to understand our practices.
</span>
        </div>
        <div style="margin-top: 30px">
          <span style="font-weight: bold">2. Age Restriction: </span>
          <span>You must be sixteen (16) years of age or older to use the Company Services. The
Company Services are available to legal residents of the United States, Canada (excluding Quebec), and the United Kingdom who are at least sixteen (16) years old and the age of majority in his or her jurisdiction of
residence and have an Internet connection or mobile phone.
</span>
        </div>
        <div style="margin-top: 30px">
          <span style="font-weight: bold">3. Other Restrictions: </span>
          <span>In order to participate in the Company Services offered by Lucky Maker., you may not be listed on any United States Government list of prohibited or restricted parties. You also must be able to represent and warrant that you are not subject to backup withholding tax because you are exempt from backup withholding or because you have not been notified by the Internal Revenue Service (IRS) that you are subject to backup withholding as a result of failure to report all interest or dividends, or because the IRS has notified you that you are no longer subject to backup withholding. Lucky Maker. is required by law to report all prizes paid out and will provide a 1099 Misc. at the end of the year to you detailing what prizes were awarded to you to the IRS.
</span>
        </div>
        <div style="margin-top: 30px">
          <span style="font-weight: bold">4. License to Use:</span>
          <span> Subject to your compliance with these Terms, Lucky Maker. grant you a limited,
non-exclusive, non-transferable, non-sublicensable license to access and make personal and non-commercial use of the App and Company Services. This license does not include any downloading, copying, or other use of information for the benefit of any third party; or any use of data mining, robots, or similar data gathering and
extraction tools. All rights not expressly granted to you in these Terms are reserved and retained by Lucky Maker or its licensors, suppliers, publishers, rights-holders, or other content/services providers. You may not misuse the App or Company Services and may use the App and Company Services only as permitted by law. The licenses granted by Lucky Maker. terminate if you do not comply with these Terms.
</span>
        </div>
        <div style="margin-top: 30px">
          <span style="font-weight: bold">5. Limited Offering of Services: </span>
          <span>Neither the availability of the App to download, nor the availability of Company Services shall be construed as an offer or invitation by Lucky Maker. to use download the App or use the Company Services if you reside in a country or jurisdiction in which such use is forbidden by law the country or jurisdiction is one in which Lucky Maker., in its sole discretion, elects not to offer the Company Services. You are solely responsible for determining whether your use of Company Services is legal in the country/
jurisdiction in which you reside and/or in any country/jurisdiction in which you log-on to use Company Services. Lucky Maker. shall not be responsible for any illegal or unauthorized use of Company Services.</span>
        </div>
        <div style="margin-top: 30px">
          <span style="font-weight: bold">6. Creating an Account: </span>
          <span>In order to access the App and use the Company Services, you are required to create and be logged in to the account (“Account”). You are responsible for maintaining the confidentiality of your
Account and password and for restricting access to your Account, and you agree to accept responsibility for all activities that occur under your Account or password. Lucky Maker reserves the right to refuse service,
terminate accounts, terminate your rights to use Company Services, remove or edit content, or cancel Tokens in its sole discretion. You agree that any termination of your Account and/or your right to use the Company
Services may be effected without prior notice, and acknowledge and agree that Lucky Make, Inc. may
immediately deactivate or delete your Account and all related information and files related to your Account and or bar any further access. Further, you agree that Lucky Maker. shall not be liable to you or any third-party for any termination of your right to use or otherwise access your Account or use the Company Services.
</span>
        </div>
        <div style="margin-top: 30px">
          <span style="font-weight: bold">7. Prohibited Conduct. </span>
          <span>You shall not, and shall not permit anyone else to, directly or indirectly: (i) modify,
reproduce or otherwise create derivatives of any part of the Company Services or App (including all content
contained therein); (ii) reverse engineer, disassemble, decompile or otherwise attempt to discover the source code or structure, sequence and organization of all or any part of the Company Services (except that this
restriction shall not apply to the limited extent restrictions on reverse engineering are prohibited by applicable local law); (iii) engage in any conduct that restricts or inhibits anyone’s use or enjoyment of the App or Company Services, or which, as determined by us, may harm Lucky Maker. or users of our App or Company
Services; (iv) use the Company Services in any manner that could disable, overburden, damage, or impair the App or interfere with any other party’s use of the Company Services, including their ability to engage in real time activities through the Company Services; (v) use any robot, spider or other automatic device, process or means to access the Services for any purpose, including monitoring or copying any of the material on the App; (vi)
attempt to gain unauthorized access to, interfere with, damage or disrupt any parts of the Company Services, the servers on which the Lucky Maker’s data is stored, or any server, computer or database used to
provide our Company Services; (vii) engage in any fraudulent activity or engage in any activity that facilitates fraud; or (viii) otherwise attempt to interfere with the proper working of the Company Services.
</span>
        </div>
        <div style="margin-top: 30px">
          <span style="font-weight: bold">8. Lucky Maker Content: </span>
          <span>Lucky Maker. and/or its licensors retain all rights to all data and information on its App and Company Services, including text, graphics, images, designs, articles, business processes, and any other form of content (collectively referred to as “Content”). Users shall have only those rights in and to the
Content that are expressly granted to it pursuant to these Terms, and are otherwise reserved. Reproducing, copying or distributing any Content for any other purpose is strictly prohibited without the express prior written permission of Lucky Maker. The Company Services may enable a user to link to websites, and access to content, products or services of third parties. Lucky Maker. is not responsible for any third party websites, or third party content provided on or through the App or Company Services. You bear all risks associated with the access and use of such websites and third party content, products and services. You access third party
content at your own risk.
</span>
        </div>
        <div style="margin-top: 30px">
          <span style="font-weight: bold">9. Your Feedback: </span>
          <span>Lucky Maker. welcomes feedback, comments and suggestions for improvements to our App or Company Services (“Feedback“). Any Feedback you submit will be considered non-confidential and non-proprietary to you. By submitting Feedback, you grant Lucky Maker. a non-exclusive, worldwide,
royalty-free, irrevocable, sub-licensable, perpetual license to use and publish those ideas and materials for any purpose, without compensation to you.
</span>
        </div>
        <div style="margin-top: 30px">
          <span style="font-weight: bold">10. Trademarks:</span>
          <span> All of the trademarks, service marks, and logos displayed on App (the “Trademarks”) are
registered and unregistered trademarks of the Sponsor its affiliates, or third parties. Nothing in this App should be construed as granting, by implication, estoppel, or otherwise, any license or right in and to the Trademarks without the Sponsor’s express written permission or the express written permission of the applicable third party. Except as expressly provided in this Agreement, any use of the Trademarks is expressly prohibited.
</span>
        </div>
        <div style="margin-top: 30px">
          <span style="font-weight: bold">11. Copyrights:</span>
          <span> The Sponsor respects the intellectual property rights of others, and asks that all Players do the same. As such, the infringement of others’ intellectual property rights will not be tolerated and may result in the termination of the infringing party’s account. Please note that the Sponsor can only address infringing materials posted on the App that have been reported to us, and cannot be responsible if any User Generated Content is repurposed or otherwise copied from the App and used in other mediums prior to the Sponsor removing such
infringing content. If a Player believes that his or her work has been copied in a way that constitutes copyright
infringement, in accordance with the Digital Millennium Copyright Act, he or she will need to provide the following information to the Sponsor’s agent:
</span>
        </div>
        <div style="margin-top: 30px">(a) an electronic or physical signature of the person authorized to
          act on behalf of the copyright owner;
        </div>
        <div style="margin-top: 30px">(b) a description of the copyrighted work that he/she claims has been
          infringed;
        </div>
        <div style="margin-top: 30px">(c) a description of where the material he/she claims is infringing is
          located on the App;
        </div>
        <div style="margin-top: 30px">(d) the Player’s address, telephone number, and email address;
        </div>
        <div style="margin-top: 30px">(e) a statement that he/she has a good faith belief that the disputed
          use is not authorized by the copyright owner, its agent, or the law;
        </div>
        <div style="margin-top: 30px">(f) A statement made by the Player, under the penalty of perjury, that
          the above information in his/her notice is accurate and that he/she is the copyright owner or duly authorized
          to
          act on the copyright owner’s behalf; and
        </div>
        <div style="margin-top: 30px">(g) The Sponsor’s agent may be contacted as follows:
          support@luckymaker.com
        </div>

        <div style="margin-top: 30px;font-weight: bold">12. Official Rules:</div>
        <div style="margin-top: 30px">
          <span style="font-weight: bold">(a)  Eligibility: </span>
          <span>The Games are open to: (1) persons who are legal residents of one of the Fifty (50) United States, the District of Columbia, Canada (excluding Quebec), and the United Kingdom; (2) are at least sixteen (16) years of age, or the age of legal majority to enter into contracts or participate in games in the jurisdiction in which they reside at the time of entry; and (3) are valid and authorized account holders of a Lucky Maker
Account (“Eligible Person”). An Eligible Person’s entry into the Sweepstakes will be deemed to be a
representation that the Eligible Person meets all of the eligibility requirements. Officers, directors, managers, and employees of Sponsor, and their respective parent companies, subsidiaries, affiliates, suppliers, advertising and promotional agencies, and immediate family members (herein defined as spouse, siblings, children and each of their
respective spouses, regardless of residence, and any person living in such a person’s household, whether
related or not), are not eligible to participate.
</span>
        </div>
        <div style="margin-top: 30px">
          <span style="font-weight: bold">(b) Tokens:</span>
          <span>By playing in Lucky Maker Games, Eligible Persons have an opportunity to win virtual tokens
(“Tokens”), which can be used to, in Lucky Maker’s sole discretion, exchange with other virtual digital goods, or obtain discounts, offers or other goods or services. Tokens are not real money, and do not have monetary value. Lucky Maker. make no guarantee as to the nature, quality or value of the features of any third-party good or services that will be accessible through the use of Tokens, or the availability or supply of Tokens. Tokens
obtained via Games are provided to you under a limited, personal, revocable, non-transferable, non-sub-
licensable license solely to use within the App. Tokens may not be transferred or resold in any manner,
including, without limitation, by means of any direct sale or auction service. Prizes may be subject to a separate third-party license or agreement. You have no property interest, right or title in or to any such Tokens. Any Tokens balance shown in your account does not constitute a real-world balance or reflect any stored value, but instead constitutes a measurement of the extent of your license. Lucky Maker. may, at any time, expire free or promotional Tokens given to you. You agree that under no circumstances is Lucky Maker. liable to you for any damages or claims that may arise from the loss or use of your Tokens regardless of the circumstances. You absolve us of any responsibility to maintain or update your Tokens account. However, if there is a loss of Tokens in your account due to technical or operational problems with the Services, Lucky Maker. will
replace the lost Tokens once the loss has been verified. Without limiting any of the foregoing, our maximum
liability or responsibility to you is to replacement of the Tokens lost.
</span>
        </div>
        <div style="margin-top: 30px">
          <span style="font-weight: bold">(c) Redeeming Tokens: </span>
          <span>Lucky Maker. will, in our sole discretion, determine and communicate the
availability and exchange rate for any Tokens, which may be modified at any time. You must comply with any individual limitations as indicated via the App to redeem for a Redeemed Good. You may choose a Redeemed Good that is still available for which you have accumulated sufficient Tokens for redemption. Select the
Redeemed Good and follow and instructions to complete the exchange process. As part of the exchange
process, you may receive a confirmation email or message from us and/or our designee or partner. Emails or messages will be sent to the email address or other contact information assigned to your account. All
acquisitions of Tokens and exchanges for Redeemed Goods are final. Once Tokens have been lost or spent, they will be subtracted from your account and cannot be refunded or returned, except in our sole discretion. No Tokens will be re-credited to your account in the event of a return or exchange of a Redeemed Good, or any problem with any Redeemed Good.</span>
        </div>
        <div style="margin-top: 30px">
          <span style="font-weight: bold">(d) Expiration of Tokens: </span>
          <span>If a Player doesn’t play a Game for ninety (90) consecutive days, a charge one
million (1,000,000) Tokens per month will be assessed against the Player’s Prize Wallet which will offset any
accrued Prize balances in the Player’s Account. Tokens are not real money, do not have monetary value, and may not be exchanged for anything of monetary value from outside our Company Services.
</span>
        </div>
        <div style="margin-top: 30px">
          <span style="font-weight: bold">(e) Payouts: </span>
          <span>Players may choose to redeem Tokens for cash. Once Players accumulate the equivalent of at least Ten Dollars ($10.00) in their Account (the “Minimum Payout Threshold”), they can cash out using PayPal. Any Players with less than the Minimum Payout Threshold will have their Prizes stored in Winner’s Prize wallet (the “Winner’s Prize Wallet”) until they reach the Minimum Payout Threshold in Prize winnings. Winners can have their Prize payments sent to their PayPal account by providing Sponsor their email address (account name). Sponsor is not responsible for lost or forfeited payouts attributed to Player providing incorrect PayPal
account information. Prizes will be processed and remitted within seven (7) business days from the date in which a request for Payout is made. Lucky Maker. may delay or cancel any Payout for purposes of preventing
unlawful activity or fraud, risk assessment, security, or investigation.
</span>
        </div>
        <div style="margin-top: 30px">
          <span style="font-weight: bold">(f) Taxes; </span>
          <span>Prize Replacement: Winners whose aggregate Redeemed Goods values exceed $600.00 in any
calendar year is solely responsible for all applicable federal, state and local taxes related thereto and will receive an IRS Form 1099 for the value of all Redeemed Goods. Except for charitable donations, no transferring of
Redeemed Goods or accounts will be permitted. For non-cash Redeemed Goods, the Sponsor reserves the right to substitute the Redeemed Good for one of equal or greater value in the event an advertised Redeemed Good becomes unavailable. Any difference between the actual value of any non-cash Redeemed Good and the approximate retail value set forth in the App rules may not be claimed and will not be awarded.</span>
        </div>

        <div style="margin-top: 30px;font-weight: bold">13. Scratcher Game</div>
        <div style="margin-top: 30px">
          <span style="font-weight: bold">(a) How to Play: </span>
          <span>Players can receive up to forty-six (46) virtual scratcher cards (“Scratcher Cards”) during a Daily Promotional Period by logging in to the App during various times throughout the Daily Promotion Period to receive Scratcher Cards as follows: (a) thirty (30) Scratcher Cards are available at 12:00:01 AM (PST). Each daily allocation of Scratcher Cards will expire at the end of each Daily Promotion Period. Once received, Players can play their Scratcher Cards by virtually scratching them. Players will be shown a short advertising video after every three (3)Scratcher Cards played. Scratching the Scratcher Card will immediately reveal if the Player has won Tokens
            and the amount of the Tokens or cash won. Prizes attributed to winning unclaimed Scratcher Cards will be deemed forfeited. Prior to the commencement of each Daily Promotion Period, the Sponsor will randomly select a winning Scratcher Card(s) for Daily Promotion Period winners, if any. Players that scratch off their Scratcher Cards prior to the expiration of the Daily Promotion Period will have claimed their entries. If a Player has been allocated a winning Scratcher Card and fails to claim it during the Daily Promotion Period, such Scratcher Card will be deemed void and the associated prize deemed forfeit. To win, a Player needs to match three of the featured colored icon on the virtual scratcher game.</span>
        </div>
        <div style="margin-top: 30px">
          <span style="font-weight: bold">(b) Prize Tokens: </span>
          <span>All Prizes awarded in the Scratcher Game are in the form of Tokens which may be exchanged for Redeemed Goods, including cash.</span>
        </div>

        <div style="margin-top: 30px">
          <span style="font-weight: bold">(c) How Winners Are Selected:</span>
          <span>Winners will be selected at random using a computer algorithm embedded in the Sponsor’s software. Winner determination will be made instantly at time of Game play, subject to accepting the Declaration of Compliance.
            Limit one (1) Prize per Game play. Players agree to be bound by these Official Rules and by the decisions of the Sponsor, which are final and binding in all respects. Unless otherwise prohibited, Players will be required to digitally
            accept the Declaration of Compliance upon conclusion of the Game or the Prize will be forfeited. If a Player does not accept the Declaration of Compliance or does not claim a Prize through App within the same business day, the Prize may be forfeited.
            If a Winner cannot be contacted or is disqualified, the Sponsor reserves the right to not award the Winner’s Prize. Certain restrictions may apply. Players trying to extort prizes or money by digitally manipulating images, making false win claims, or otherwise violating the Official Rules and/or the Terms of Service will have their account terminated and all pending transactions cancelled.
          </span>
        </div>

        <div style="margin-top: 30px">
          <span style="font-weight: bold">(d) Prizes and Odds: </span>
          <span>The odds of winning Scratcher Game depend on number of Game Players, the total entries for each Daily Promotion Period, and the percentage of Scratcher Cards claimed by Players. Tokens have no market value and are awarded on an instant win basis for every Scratcher Card a Player claims.</span>
        </div>

        <div style="margin-top: 30px;font-weight: bold"> 14. Lottery Game</div>
        <div style="margin-top: 30px">
          <span style="font-weight: bold">(a) How to Play:</span>
          <span> In order to play, a Eligible Person must click on the Lottery Game link in the App during any Daily Promotion Period (at which point the Eligible Person becomes a Player) and select six numbers (or use the “quick pick” option to have them randomly selected) which become the Player’s Lottery Numbers.
            Selecting the Lottery Numbers constitutes on Lottery Game Entry. Players are limited to one (1) Lottery Game Entry for each Daily Promotion Period.</span>
        </div>
        <div style="margin-top: 30px">
          <span style="font-weight: bold">(b) Prizes and Odds: </span>
          <span>The odds of winning a Prize and a description of each Prize is as follows:</span>
        </div>
        <el-table
            :data="titleData"
            border
            style="width: 100%;margin-top: 30px">
          <el-table-column
              prop="matche"
              label="Matches">
          </el-table-column>
          <el-table-column
              prop="amount"
              label="Prize Amount">
          </el-table-column>
          <el-table-column
              prop="type"
              label="Prize Type">
          </el-table-column>
          <el-table-column
              label="Odds of Winning"
              prop="desc">
          </el-table-column>
        </el-table>
        <div style="margin-top: 30px">
          <span style="font-weight: bold">(c) Winning:</span>
          <span> [Every day at 7pm PST, the previous game for the previous game is revealed upon opening the Lottery section and then you can play again.].</span>
        </div>

        <div style="margin-top: 30px;font-weight: bold"> 15. Raffle Game</div>
        <div style="margin-top: 30px">
          <span style="font-weight: bold">(a) How to Play: </span>
          <span>For each Daily Promotion Period, you will be eligible to receive one (1) Ticket which constitutes an entry into one of the Raffle Games you select for that Daily Promotion Period.  You can enter additional times by exchanging additional Tokens per Ticket entry (the number of Tokens depends on the prizing for that specific Raffle).
            You are limited to 1,000 Ticket entries per Raffle Game per Daily Promotion Period. </span>
        </div>
        <div style="margin-top: 30px">
          <span style="font-weight: bold">(b) Odds of Winning: </span>
          <span>For each Daily Promotion Period. Additionally, a Player may exchange Tokens for additional tickets to any open and available raffle drawings up to 1,000 entries per Raffle Game. Players are eligible to play between one (1) and six (6) raffle games per day (depending on the number raffle games offered during that specific Daily Promotion Period) with the start and end times indicated in the specific raffle game.
            Player decides which raffle game he/she wants to play. Each Ticket costs a different amount of Tokens depending on the prize for that Raffle Game. Each Raffle Game will have one (1) winner selected at random by the Sponsor’s computers when the time runs out for the specific Raffle Game.</span>
        </div>
        <div style="margin-top: 30px">
          <span style="font-weight: bold">(c) Winning: </span>
          <span> Prizes for the Raffle Game will be awarded to winning Players from February 1, 2019 through January 30, 2020 (the “Raffle Promotion Period”). Raffle Game prizes will vary for each Daily Promotion Period and the number of Raffle Game prizes available per Daily Promotion Period may range between one and twenty. The ARV for each Raffle Game Prize will be disclosed with each Raffle Game.
            Odds of winning a Raffle Game depend on total entries for such Raffle Game during the Daily Promotion Period.</span>
        </div>

        <div style="margin-top: 30px">
          <span style="font-weight: bold">16. Release of Liability:</span>
          <span>In consideration of being permitted to access and use the Services, you hereby agree to release Lucky Maker. and its affiliates and subsidiaries, and their officers, directors, employees and agents from all damages (whether direct, indirect, incidental, consequential or otherwise), losses, liabilities, costs and expenses of every kind and nature, known and unknown,
            arising out of or in any way connected with disputes between you and third parties (including Organizers, Players, and other Users) in connection with the Services or your access and use of the Services. In connection with the foregoing release, you hereby waive California Civil Code 1542 and any other applicable law or statute, which says, in substance: “A general release does not extend to claims which the creditor does not know or suspect to exist in his favor at the time of executing the release,
            which if known by him must have materially affected his settlement with the debtor.”</span>
        </div>

        <div style="margin-top: 30px; font-weight: bold">17. Limitation of Liability and Damages
        </div>
        <div style="margin-top: 30px; font-weight: bold">(a) The following is applicable for Users in the USA and rest
          of
          the
          world (outside the European Union):
        </div>
        <div style="margin-top: 30px">
          <ul style="margin-left: 20px">
            <li>
              (i) To the fullest extent permitted by applicable law: (i) in no event shall Lucky Maker. be liable
              for
              any direct, special, indirect, or consequential damages, or any other damages of any kind, including but
              not
              limited to loss of use, loss of profits, or loss of data, whether in an action in contract, tort
              (including but not limited to negligence) or otherwise, arising out of or in any way connected with the
              use
              of
              or inability to use the Company Services, the Content, the App, or any other materials including without
              limitation any damages caused by or resulting from reliance on any information obtained from Lucky Maker,
              Inc.; and (ii) in no event shall the aggregate liability of Lucky Maker., whether in contract,
              warranty,
              tort (including negligence, whether active, passive, or imputed), product liability, strict liability, or
              other theory, arising out of or relating to the use of or inability to use the Services exceed the amount
              paid
              to Lucky Maker. by you, if any, for accessing the Services during twelve (12) months immediately
              preceding the date of the claim or one hundred dollars ($100), whichever is greater.
            </li>
            <li style="margin-top: 30px">(ii) These limitations of liability also apply with respect to damages incurred
              by you by reason of any
              services provided by third parties other than Lucky Maker.
            </li>
            <li style="margin-top: 30px">(iii) You acknowledge and agree that Lucky Maker. has offered the Company
              Services and
              entered into these Terms in reliance upon the warranty disclaimers and the limitations of liability set
              forth
              herein, that the warranty disclaimers and the limitations of liability set forth herein reflect a
              reasonable and fair allocation of risk between you and Lucky Maker., and that Lucky Maker. would
              and
              that the warranty disclaimers and the limitations of liability set forth herein form an essential basis of
              the
              bargain between you and Lucky Maker.
            </li>
          </ul>
        </div>
        <div style="margin-top: 30px">
          <span style="font-weight: bold">(b) The following is applicable to Users in the European Union: </span>
          <span>Neither Lucky Maker. and its affiliates and partners in all cases, nor you, will be responsible for: (i) losses that were not caused by any breach on their or your part; (ii) any indirect or consequential losses (including loss of profits, revenue, contracts, anticipated savings, data, goodwill or wasted expenditure); or (iii) any indirect or consequential losses that were not
foreseeable by both you and us when these terms of service were agreed or updated as applicable. You are not granted any rights under this section.</span>
        </div>
        <div style="margin-top: 30px">
          <span style="font-weight: bold">18. Indemnification.</span>
          <span>Each Player agrees to indemnify, defend and hold harmless the Sponsor, its affiliates,
officers, directors, employees, agents, information providers, partners, advertisers and suppliers (the “The
Protected Parties”) from and against all losses, expenses, damages and costs, including reasonable attorney’s fees, resulting from (a) provision of Unsolicited Information or User Generated Content by him/her, or (b) his/her use of the App; and/or (c) his/her breach of the terms of these Official Rules (including infringement of third
parties’ worldwide intellectual property rights or negligent or wrongful conduct) or a breach by any other person accessing the App using his/her account.</span>
        </div>


        <div style="margin-top: 30px;font-weight: bold">19. Arbitration. ONLY FOR USERS LOCATED IN THE UNITED STATES.
        </div>
        <div style="margin-top: 30px">PLEASE READ THIS SECTION CAREFULLY AS IT AFFECTS YOUR RIGHTS. ANY
          DISPUTE OR CLAIM UNDER THESE TERMS OF SERVICE OR WITH RESPECT TO THE SERVICES WILL BE SETTLED BY
          BINDING ARBITRATION OR IN SMALL CLAIMS COURT (TO THE EXTENT THE CLAIM QUALIFIES) AND WILL TAKE PLACE ON AN
          INDIVIDUAL BASIS ONLY; CLASS, CONSOLIDATED OR REPRESENTATIVE
          ARBITRATIONS AND CIVIL ACTIONS ARE NOT PERMITTED ONCE THIS SECTION IS EFFECTIVE.
        </div>

        <div style="margin-top: 30px">
          <span style="font-weight: bold">(a) Pre-Arbitration Dispute Resolution. </span>
          <span>Prior to initiating an arbitration, you and Lucky Maker. each agree to notify the other party of the dispute and attempt to negotiate an informal resolution to it first. Lucky Maker. will contact you at the email address you have provided to us; you can contact Lucky Maker. by
emailing us. If after a good faith effort to negotiate one of us feels the dispute has not and cannot be resolved
informally, the party intending to pursue arbitration agrees to notify the other party via email prior to initiating the arbitration.
      </span>
        </div>
        <div style="margin-top: 30px">
          <span style="font-weight: bold">(b) Agreement to Arbitrate. </span>
          <span>You and Lucky Maker. agree that any dispute, claim, or controversy, whether based in contract, tort, statute, fraud, misrepresentation or any other legal theory, arising out of or relating to these Terms of Service or the breach, termination, enforcement, interpretation or validity thereof, or to the use of the Services (collectively, “Disputes”) will be settled through binding arbitration and not in a court of law. You and Lucky Maker. each hereby agree to resolve any and all disputes or claims under these Terms of Service or with respect to the Services through binding arbitration or in small claims court (to the extent the claim qualifies) instead of in courts of general jurisdiction and only on an individual basis and not as part of any purported class, consolidated or representative proceeding. Only the arbitrator appointed pursuant to this Section, and not any federal, state or local court or agency, shall have the authority to resolve any dispute or claim relating to this Section, including, without limitation, the scope, enforceability and arbitrability of these Terms of Service. This
arbitration provision shall survive termination of these Terms of Service. These Terms of Service evidence a transaction in interstate commerce and the interpretation and enforcement of this Section is governed by the Federal Arbitration Act, notwithstanding the choice of law set forth in these Terms of Service.

</span>
        </div>
        <div style="margin-top: 30px">
          <span style="font-weight: bold">(c) About Binding Arbitration. </span>
          <span>Arbitration, which is often cheaper, faster and less formal than a lawsuit in court, uses a neutral arbitrator instead of a judge or jury. Arbitrators can award the same damages and relief as a court. Binding arbitration is subject to very limited review.
      </span>
        </div>
        <div style="margin-top: 30px">
          <span style="font-weight: bold">(d) Scope of Agreement. </span>
          <span>This agreement to arbitrate is intended to be broadly interpreted. It includes, but is not limited to: (i) all claims arising out of or relating to any aspect of the relationship between us, whether based in contract, tort, statute, fraud, misrepresentation or any other legal theory; (ii) all claims that arose before this or any prior agreement (including, but not limited to, claims relating to advertising); and (iii) all claims that may arise after termination of these Terms of Service and/or your use of the Services.

</span>
        </div>
        <div style="margin-top: 30px">
          <span style="font-weight: bold">(e) Exceptions. </span>
          <span>Notwithstanding this Agreement to arbitrate, either party may (i) bring an action on an individual basis in small claims court (to the extent the applicable claim qualifies), (ii) bring issues to the attention of
federal, state or local agencies, including, for example, the Federal Trade Commission and the California
Division of Consumer Services, which agencies may be able to seek relief on a party’s behalf (the Complaint
Assistance Unit of the Division of Consumer Services may be contacted in writing  and (iii) bring suit in court to seek a preliminary injunction or other interim relief pending the outcome of arbitration.

</span>
        </div>
        <div style="margin-top: 30px">
          <span style="font-weight: bold">(f) No Class Actions. </span>
          <span>YOU AND Lucky Maker. AGREE THAT EACH MAY BRING CLAIMS AGAINST THE OTHER ONLY IN YOUR OR ITS INDIVIDUAL CAPACITY, AND NOT AS A PLAINTIFF OR CLASS MEMBER IN ANY PURPORTED CLASS, CONSOLIDATED OR REPRESENTATIVE PROCEEDING. THE
ARBITRATOR MAY NOT CONSOLIDATE MORE THAN ONE PERSON’S CLAIMS, MAY NOT PRESIDE OVER ANY FORM OF CLASS, CONSOLIDATED OR REPRESENTATIVE PROCEEDING AND MAY ONLY PROVIDE RELIEF IN FAVOR OF THE INDIVIDUAL PARTY SEEKING RELIEF AND ONLY TO THE EXTENT
NECESSARY TO PROVIDE RELIEF WARRANTED BY THAT PARTY’S INDIVIDUAL CLAIM.

</span>
        </div>
        <div style="margin-top: 30px">
          <span style="font-weight: bold">(g) Notice of Dispute. </span>
          <span>A party who intends to seek arbitration must first send to the other a written Notice of
Dispute (“Notice”). The Notice to Lucky Maker. must be addressed to the address in Section 12.4 below (“Notice Address”) and must be sent by certified mail. The Notice to you must be addressed to a mailing, home or payment address currently on record with Lucky Maker. and must be sent by certified mail. If Lucky Macer, Inc. has no records of such physical address, such notice may be delivered to your Lucky Maker. account email address. The Notice must (i) describe the nature and basis of the claim or dispute; and (ii) set forth the specific relief sought. If Lucky Maker. and you do not reach an agreement to resolve the claim within sixty (60) calendar days after the Notice is received, you or Lucky Maker. may commence an
arbitration proceeding.

</span>
        </div>
        <div style="margin-top: 30px">
          <span style="font-weight: bold">(h) Arbitration Proceedings. </span>
          <span>The arbitration will be governed by the Commercial Arbitration Rules, or, if the
actions giving rise to the dispute or claim relate to your personal or household use of the Services (rather than business use), the Consumer Arbitration Rules (in each case, the “AAA Rules”) of the American Arbitration
Association (“AAA”), as modified by this Section 6.10, and will be administered by the AAA and settled by a single arbitrator. The AAA Rules are available online at adr.org or by calling the AAA at 1-800-778-7879. The
arbitrator is bound by the terms of these Terms of Service. All issues are for the arbitrator to decide, including, but not limited to, issues relating to the scope, enforceability, and arbitrability of this Section 11.8. Unless Lucky Maker. and you agree otherwise, any arbitration hearings will take place in a reasonably convenient
location in the United States for both parties with due consideration of their ability to travel and other pertinent circumstances. If the parties are unable to agree on a location, AAA shall determine the location. If your claim is for ten thousand dollars ($10,000) or less, Lucky Maker. agree that you may choose whether the arbitration will be conducted solely on the basis of documents submitted to the arbitrator, through a telephonic hearing, or by an in-person hearing as established by the AAA Rules. If your claim exceeds ten thousand dollars ($10,000), the right to a hearing will be determined by the AAA Rules. Regardless of the manner in which the arbitration is conducted, the arbitrator shall issue a reasoned written decision sufficient to explain the essential findings and conclusions on which the award is based. All decisions by the arbitrator shall be final and binding and judgment on the award rendered may be entered in any court having jurisdiction.</span>
        </div>
        <div style="margin-top: 30px">
          <ul style="margin-left: 20px">
            <li><span style="font-weight: bold">(i) Costs of Arbitration; Legal Fees.</span></li>
            <li style="margin-top: 30px;margin-left: 20px">(i) Payment of all filing, administration, and arbitrator
              costs
              and expenses imposed by AAA will be
              governed by the AAA rules, provided that if you are initiating an arbitration against Lucky Maker.
              and the value of the relief sought is ten thousand dollars ($10,000) or less, then Lucky Maker.
              will
              advance all filing, administrative and arbitration costs and expenses imposed by AAA (subject to
              reimbursement as set forth below). If the circumstances in the preceding sentence apply, but the value
              of
              relief sought is more than ten thousand dollars ($10,000) and you demonstrate to the arbitrator that
              such
              costs and expenses would be prohibitively more expensive than a court proceeding, then Lucky Maker,
              Inc.
              will pay the amount of any such costs and expenses that the arbitrator determines are necessary to
              prevent
              the arbitration from being prohibitively more expensive than a court proceeding (subject to
              reimbursement as set forth below). In the event that the arbitrator determines that all of the claims
              you
              assert in arbitration are frivolous according to Federal Rule of Civil Procedure 11, you agree to
              reimburse Lucky Maker. for all such cost and expenses that Lucky Maker. paid and that you
              would have been obligated to pay under the AAA rules.
            </li>
            <li style="margin-top: 30px;margin-left: 20px">(ii) Just as in any court proceeding, each party will
              initially
              bear its own
              attorneys’ fees and
              expenses in connection with any arbitration. Should either party be determined to have substantially
              prevailed in the arbitration, then upon such party’s request, the arbitrator shall award such prevailing
              party the reasonable attorneys’ fees and expenses that it incurred in connection with the arbitration,
              provided that to the extent that the dispute or claim relate to your personal or household use of the
              Services (rather than business use) Lucky Maker. will not seek to recover its attorneys’ fees and
              expenses in an arbitration initiated by you. The arbitrator may make rulings and resolve disputes as to
              the reimbursement of attorneys’ fees and expenses upon request from either party made within fourteen
              (14)
              days of the arbitrator’s ruling on the merits.
            </li>
            <li style="margin-top: 30px"><span style="font-weight: bold"> (j) Future Changes. </span>
              <span>Notwithstanding any provision in these Terms of Service to the contrary, you and Lucky Maker. agree
            that if Lucky Maker. makes any future change to this arbitration provision (other than a change to the
            Notice Address) Lucky Maker. will provide you with notice of such change and you may reject any such
            change by sending us written notice within thirty (30) calendar days of the change to the Notice Address
            described above. By rejecting any future change, you are agreeing that you will
            arbitrate any dispute between us in accordance with the language of this provision as unmodified by such
              rejected change.</span>
            </li>
          </ul>
        </div>
        <div style="margin-top: 30px">
          <span style="font-weight: bold">(k) Special Severability. </span>
          <span>In the event that the provisions of Section 11.6 above are found to be invalid or
unenforceable for any dispute or claim, then, notwithstanding Section 12.3 (“No Waiver”), the entirety of this Section 11 shall be null and void with respect to such dispute or claim and Section 12.2 shall apply in lieu of this Section 11.</span>
        </div>

        <div style="margin-top: 30px;font-weight: bold">20. General Terms</div>
        <div style="margin-top: 30px">
          <span style="font-weight: bold">(a) Governing Law. </span>
          <span>These Terms of Service shall be governed by and construed in accordance with the laws of the State of California and the federal laws of the United States of America. The parties agree that the Uniform Computer Information Transactions Act as enacted any State of the United States shall not apply to this
Agreement or any performance hereunder and the parties expressly opt-out of the applicability of UCITA to this Agreement.
</span>
        </div>
        <div style="margin-top: 30px">
          <span style="font-weight: bold">(b) Forum. </span>
          <span>In the event that the Agreement to Arbitrate above is found not to apply to you or to a particular claim or dispute, either as a result of your decision to opt-out of the Agreement to Arbitrate or for any other reason, then any dispute or claim not subject to arbitration shall be resolved exclusively by a federal court located in Orange County, California, and to the extent there is no subject matter jurisdiction in such federal court, then a state court in Orange County, California. Both you and Lucky Maker. agree to submit to the personal
jurisdiction and venue of such courts and agree that such forum is convenient.
</span>
        </div>
        <div style="margin-top: 30px">
          <span style="font-weight: bold">(c) No Waiver. </span>
          <span>The failure of either party at any time to require performance by the other party of any provision of these Terms of Service shall in no way affect that party’s right to enforce such provisions, nor shall the waiver by either party of any breach of any provision of this Terms of Service be taken or held to be a waiver of any
further breach of the same provision.
</span>
        </div>
        <div style="margin-top: 30px">
          <span style="font-weight: bold">(d) Notice. </span>
          <span>Notices to you may be sent via either email at support@luckymaker.com . The Services may also provide notices of changes to these Terms of Service or other matters by displaying notices or links to notices to you generally on the Services.
</span>
        </div>
        <div style="margin-top: 30px">
          <span style="font-weight: bold">(e) Assignment. </span>
          <span>These Terms of Service, and any rights and licenses granted hereunder, may not be
transferred or assigned by you, but may be assigned by Lucky Maker. without restriction. Any assignment
attempted to be made in violation of these Terms of Service shall be void.
</span>
        </div>
        <div style="margin-top: 30px">
          <span style="font-weight: bold">(f) Severability.</span>
          <span> If any provision of these Terms of Service or any guidelines is held to be unlawful, void, or for any reason unenforceable, then for both you and Lucky Maker. that provision will be limited or eliminated from these Terms of Service to the minimum extent necessary and will not affect the validity and enforceability of any remaining provisions.
</span>
        </div>
        <div style="margin-top: 30px">
          <span style="font-weight: bold">(g) Headings. </span>
          <span>The heading references herein are for convenience purposes only, do not constitute a part of these Terms of Service, and will not be deemed to limit or affect any of the provisions of it.
</span>
        </div>
        <div style="margin-top: 30px">
          <span style="font-weight: bold">(h) Survival. </span>
          <span>Upon termination of these Terms of Service, any provision which, by its nature or express terms should survive, will survive such termination or expiration.
</span>
        </div>
        <div style="margin-top: 30px">
          <span style="font-weight: bold">(i) English Language. </span>
          <span>Communications and documents, even those from Lucky Maker., on the Site or through the Services may be in a language other than English. With respect to these Terms of Service, the
Privacy Policy, and any other agreement between you and Lucky Maker., or other policy implemented by Lucky Maker., the English language version of each of these documents is the version that governs your use of the Services and controls in the event of any conflict.</span>
        </div>


        <div style="margin-top:30px">COMPANY: FOX INFORMATION PTE. LTD.</div>
        <div style="margin-top:10px">LOCATION:160 ROBINSON ROAD #20-03 SINGAPORE (068914)</div>
        <div style="margin-top:10px">EMAIL:support@luckymaker.com</div>

      </div>

    </div>

    <div style="padding-bottom: 30px;padding-top:30px;background: #2F1A5E;text-align: center">
      <span class="sp_type" @click="jumpPrivacyPolicy">privacy policy</span>
      <span class="sp_type" @click="jumpUsePolicy" style="margin-left: 60px">Terms of use</span>
    </div>

  </div>

</template>

<script>
export default {
  name: "UserAgreement",

  data() {
    return {
      titleData: [
        {
          matche: '0',
          amount: '0',
          type: 'None',
          desc: '1: 1.5965684334',
        },
        {
          matche: '1 (1 or 0 + Lucky)',
          amount: '50,000',
          type: 'Prize Tokens',
          desc: '1: 3.1481483724',
        },
        {
          matche: '2 (2 or 1 + Lucky) ',
          amount: '250,000',
          type: 'Prize Tokens',
          desc: '1: 19.0696083692',
        },
        {
          matche: '3 (3 or 2 + Lucky)',
          amount: '500,000',
          type: 'Prize Tokens',
          desc: '1: 287.8267193624',
        },
        {
          matche: '4 (4 or 3 + Lucky)',
          amount: '600,000',
          type: 'Prize Tokens',
          desc: '1: 10,545.1559137714',
        },
        {
          matche: '5 (5 or 4 + Lucky)',
          amount: '700,000',
          type: 'Prize Tokens',
          desc: '1: 981,109.939780306',
        },
        {
          matche: '6 (6 or 5 + Lucky)',
          amount: '800,000',
          type: 'Prize Tokens',
          desc: '1: 327,690,720',
        },
      ],
      input: '',
    }
  },
  methods: {
    /**
     * 打开首页
     */
    jumpHome() {
      this.$router.push({
        path: '/',
      });
    },
    /**
     * 打开关于我们界面
     */
    jumpAboutUs() {
      this.$router.push({
        path: '/aboutUs',
      });
    },
    /**
     * 打开联系我们界面
     */
    jumpContactUs() {
      this.$router.push({
        path: '/contactUs',
      });
    },
    /**
     * 打开隐私协议
     */
    jumpPrivacyPolicy() {
      this.$router.push({
        path: '/PrivacyAgreement',
      });
    },
    /**
     * 打开用户协议
     */
    jumpUsePolicy() {
      this.$router.push({
        path: '/UserAgreement',
      });
    },
  },
}
</script>

<style scoped>

.top_style {
  display: flex;
  /*实现垂直居中*/
  align-items: center;
  padding: 10px;
  background: linear-gradient(78deg, #6131C4, #623BF0);
}

.sp_type {
  font-size: 16px;
  color: white;
  font-family: Arial;
}

.span_center {
  width: 100%;
  font-weight: bold;
  font-size: 26px;
  color: white;
  margin-left: 10px;
}

.tab_type {
  font-size: 16px;
  color: white;
  font-family: Arial;
}


</style>